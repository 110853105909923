import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseDeepEqualEffectExample } from './use-deep-equal-effect.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usedeepequaleffect"
    }}>{`useDeepEqualEffect`}</h1>
    <p>{`Use `}<inlineCode parentName="p">{`useDeepEqualEffect`}</inlineCode>{` when react normal dependecy compare feature doesn't do
it. This hook is used in the same way as `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` but will do a deeper
equality check on the dependecy array than React's version.`}</p>
    <p>{`There is also a `}<inlineCode parentName="p">{`useDeepEqualLayoutEffect`}</inlineCode>{` exported that behaves exactly the
same, but will run at the same stage as `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const dep = { an: 'object' };
useEffect(effect, [dep]); // this will rerun after each render
useDeepEqualEffect(effect, [dep]); // this will on run on initial render
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useDeepEqualEffect } from '@fransvilhelm/hooks';

const Checkbox = ({ dep }) => {
  useDeepEqualEffect(() => {
    doSomethingCostly(dep);
  }, [dep]);
  return <p />;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseDeepEqualEffectExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseDeepEqualEffectExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseDeepEqualEffectExample mdxType="UseDeepEqualEffectExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useDeepEqualEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useDeepEqualLayoutEffect`}</inlineCode>{` accepts same kind of
arguments as `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`effect`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`() => (void \\| () => void)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Effect to run after render (when dependecy array are not deep equal)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dependecies`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`any[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Dependecies to check before running effect`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      